import * as Sentry from '@sentry/svelte';

// Initialize the Sentry SDK here
if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://79be4fa05252496288f7263e3dd8e8d6@o4505048454660096.ingest.sentry.io/4505049181913088',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}
